import React, { useMemo, useState, useEffect, useRef, Suspense } from "react";
import { FixedSizeList as List } from "react-window";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Accordion, Tabs, Tab } from "react-bootstrap";
import {
  getClass,
  getClassAssessments,
  getTeachers,
  getResultsByStudent,
  getClassesAssessmentResults,
  getIndividualStudentResults,
} from "../services/APIClient";
import "./SencoStudentsResultsOverview.css";
import { Fallback, BigSpinner } from "../services/SpinnerComponent";
import { groupClassResults } from "../services/GroupAndAverageTasks";

// Lazy-loaded components
const SencoAssessmentDropdown = React.lazy(() =>
  import("./SencoAssessmentDropdown")
);
const SencoStudentsResultsTable = React.lazy(() =>
  import("./SencoStudentsResultsTable")
);
const RecommendationsButton = React.lazy(() =>
  import("./RecommendationsButton")
);
const ReturnToSencoResults = React.lazy(() => import("./ReturnToSencoResults"));
const SencoTaskScoreGraph = React.lazy(() => import("./SencoTaskScoreGraph"));
const PieThresholdGraph = React.lazy(() => import("./PieThresholdGraph"));
const ClassroomAnalysis = React.lazy(() => import("./ClassroomAnalysis"));
const TaskScoresComparisonChart = React.lazy(() =>
  import("./TaskScoresComparisonChart")
);

const TaskScoresComparisonChartSchool = React.lazy(() =>
  import("./TaskScoresComparisonChartSchool")
);
const StudentPerformancePieChart = React.lazy(() =>
  import("./StudentPerformancePieChart")
);
const AllStudentsPerformancePieChart = React.lazy(() =>
  import("./AllStudentsPerformancePieChart")
);
const AllStudentsIndividualSearch = React.lazy(() =>
  import("./AllStudentsIndividualSearch")
);
const StandardScoresGraph = React.lazy(() => import("./StandardScoresGraph"));
const ScoreStatements = React.lazy(() => import("./ScoreStatements"));
const CandyBearAnalysisGraph = React.lazy(() =>
  import("./CandyBearAnalysisGraph")
);

const CandyBearAnalysisGraphClassComparison = React.lazy(() =>
  import("./CandyBearAnalysisGraphClassComparison")
);

const CandyBearAnalysisGraphClassAll = React.lazy(() =>
  import("./CandyBearAnalysisGraphClassAll")
);


const CandyBearAnalysisGraphClassAverage = React.lazy(() =>
  import("./CandyBearAnalysisGraphClassAverage")
);

const ScoreInterpretationTable = React.lazy(() =>
  import("./ScoreInterpretationTable")
);
const TaskDetailChart = React.lazy(() => import("./TaskDetailChart"));
const StudentPerformancePrintViewAlphabetical = React.lazy(() =>
  import("./StudentPerformancePrintViewAlphabetical")
);

function SencoStudentsResultsOverview(props) {
  const {
    // classesResults = {},
    toggleScoreType,
    showAtRiskOnly,
    schoolid,
    teacherid,
    classIds,
    allClassesAssessments,
    assessment,
    classList,
  } = props;

  const [classDetails, setClassDetails] = useState(null);
  const [classAssessments, setClassAssessments] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedChildID, setSelectedChildID] = useState("");
  const [individualStudentResult, setIndividualStudentResult] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [isTaskDetailVisible, setIsTaskDetailVisible] = useState(false);
  const [showPrintView, setShowPrintView] = useState(false);
  const [showStudentPerformancePrintView, setShowStudentPerformancePrintView] =
    useState(false);
  const [showChildPrintOptions, setShowChildPrintOptions] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(assessment || {});
  const [isLoading, setIsLoading] = useState(true);
  const [classesResults, setClassesResults] = useState({});
  const [loadingClassId, setLoadingClassId] = useState(null);
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );

  const [activeTab, setActiveTab] = useState("overview");

  useEffect(() => {
    if (props.location?.state?.currentTab) {
      console.log("Restoring previous tab:", props.location.state.currentTab);
      setActiveTab(props.location.state.currentTab);
    }
  }, [props.location?.state?.currentTab]);


  useEffect(() => {
    if (props.location?.state?.assessment) {
      console.log("Updating selected assessment from navigation:", props.location.state.assessment);
      setSelectedAssessment(props.location.state.assessment);
    }
  }, [props.location?.state?.assessment]);


  const navigate = useNavigate();

  let fromSencoResults = true;

  const classRefs = useRef({});
  const individualChildRef = useRef();
  const candyBearRef = useRef();
  const standardScoreGraphRef = useRef();
  const studentPerformancePieRef = useRef();
  const performanceTableRef = useRef();

  console.log(schoolid);

  let name = "Results";
  const rowHeight = 100; // Approximate height of each Accordion row
  const totalClasses = Object.keys(classesResults).length;

  const fetchAssessmentResults = async () => {
    try {
      setIsLoading(true); // Set loading state to true when the API call starts
      if (assessment && assessment.instances) {
        const response = await getResultsByStudent(assessment.instances);
        setAssessmentResultsByStudent(response);
      }
    } catch (error) {
      console.error("Error fetching assessment results:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when the API call finishes
    }
  };

  // useEffect(() => {
  //   fetchAssessmentResults();
  // }, [assessment]);

  useEffect(() => {
    if (selectedAssessment && selectedAssessment.instances) {
      console.log("useEffect triggered for selectedAssessment change:", selectedAssessment.name);
      console.log("Starting fetchAssessmentResults with selectedAssessment:", selectedAssessment);
      fetchAssessmentResults(selectedAssessment);
    } else {
      console.warn("Assessment instances missing or selectedAssessment changed to:", selectedAssessment);
    }
  }, [selectedAssessment]);


  const fetchTeachers = async () => {
    try {
      const response = await getTeachers(schoolid);
      setTeachers(response);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, [schoolid]);

  const fetchIndividualStudentResults = async (studentId) => {
    try {
      const response = await getIndividualStudentResults(studentId);
      setIndividualStudentResult(response);
    } catch (error) {
      console.error("Error fetching individual student results:", error);
    }
  };

  useEffect(() => {
    if (selectedChildID) {
      fetchIndividualStudentResults(selectedChildID);
    }
  }, [selectedChildID]);

  const handleTaskClick = (taskName) => {
    // Filter classes to get only those with a score for the selected task
    const taskData = formattedClassData
      .map((classData) => ({
        className: classData.name,
        score: classData.taskScores[taskName],
      }))
      .filter((data) => data.score !== undefined); // Filter out classes without scores

    // Toggle visibility
    if (selectedTask === taskName) {
      setIsTaskDetailVisible(!isTaskDetailVisible); // Toggle visibility
      setSelectedTask(null); // Reset the selected task
      setSelectedTaskData(null); // Reset the selected task data
    } else {
      setSelectedTask(taskName);
      setSelectedTaskData(taskData);
      setIsTaskDetailVisible(taskData.length > 0); // Set visibility based on data length
    }
  };

  const thresholds = {
    Rhyming: { task: 20, student: 25 },
    "Initial Phoneme": { task: 20, student: 20 },
    "Phoneme Blending": { task: 20, student: 20 },
    "Letter Name": { task: 20, student: 15 },
    Deletion: { task: 20, student: 20 },
    "Letter Sound": { task: 20, student: 20 },
    "Word Reading": { task: 10, student: 15 },
    "Non Word Reading": { task: 10, student: 15 },
    "Phonological Awareness": { task: 10, student: 15 },
    Spelling: { task: 20, student: 20 },
    "Comprehension & Expressive Vocab": { task: 20, student: 20 },
    "Alphabetic Knowledge": { task: 40, student: 20 },
  };

  // const formattedClassData = useMemo(() => {
  //   const classScores = [];

  //   Object.entries(classesResults).forEach(([classId, classObj]) => {
  //     const students = classObj.students || {};
  //     const classTaskScores = {};

  //     Object.entries(students).forEach(([studentId, student]) => {
  //       // Change to Object.entries to get both ID and student object
  //       const studentResults = student.results || [];
  //       studentResults.forEach((result) => {
  //         const tasks = result.tasks || [];
  //         tasks.forEach((task) => {
  //           const taskName = task.name;
  //           const score = toggleScoreType
  //             ? task.groupPercentile
  //             : task.score * 100;

  //           if (!classTaskScores[taskName]) {
  //             classTaskScores[taskName] = [];
  //           }
  //           classTaskScores[taskName].push(score);
  //         });
  //       });
  //     });

  //     // Calculate average scores for the class
  //     Object.keys(classTaskScores).forEach((taskName) => {
  //       const scores = classTaskScores[taskName];
  //       const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
  //       classTaskScores[taskName] = averageScore.toFixed(2);
  //     });

  //     // Ensure assessmentResults contains the student-specific results
  //     classScores.push({
  //       id: classId,
  //       name: classObj.name,
  //       taskScores: classTaskScores,
  //       assessmentResults: Object.entries(students).flatMap(
  //         ([studentId, student]) => {
  //           // Log the student object before mapping

  //           return student.results
  //             ? student.results.map((result) => {
  //                 // Log the result structure

  //                 return {
  //                   student: {
  //                     name: student.name,
  //                     id: studentId, // Use the key directly for the student ID
  //                   },
  //                   tasks: result.tasks || [],
  //                 };
  //               })
  //             : [];
  //         }
  //       ),
  //     });
  //   });

  //   return classScores; // Return the formatted array
  // }, [classesResults, toggleScoreType]);

  // const formattedClassData = useMemo(() => {
  //   // Create a map of initial class data from classList with placeholders for details
  //   const initialData = classList.reduce(
  //     (acc, { classId, name }) => ({
  //       ...acc,
  //       [classId]: { id: classId, name, taskScores: {}, assessmentResults: [] },
  //     }),
  //     {}
  //   );

  //   // Populate this initial data with details from classesResults as they are fetched
  //   Object.entries(classesResults).forEach(([classId, classObj]) => {
  //     const students = classObj.students || {};
  //     const classTaskScores = {};

  //     Object.entries(students).forEach(([studentId, student]) => {
  //       const studentResults = student.results || [];
  //       studentResults.forEach((result) => {
  //         const tasks = result.tasks || [];
  //         tasks.forEach((task) => {
  //           const taskName = task.name;
  //           const score = toggleScoreType
  //             ? task.groupPercentile
  //             : task.score * 100;
  //           if (!classTaskScores[taskName]) {
  //             classTaskScores[taskName] = [];
  //           }
  //           classTaskScores[taskName].push(score);
  //         });
  //       });
  //     });

  //     // Calculate average scores for the class
  //     Object.keys(classTaskScores).forEach((taskName) => {
  //       const scores = classTaskScores[taskName];
  //       const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
  //       classTaskScores[taskName] = averageScore.toFixed(2);
  //     });

  //     // Merge the detailed data with initial skeleton data
  //     initialData[classId] = {
  //       ...initialData[classId],
  //       taskScores: classTaskScores,
  //       assessmentResults: Object.entries(students).flatMap(
  //         ([studentId, student]) =>
  //           student.results
  //             ? student.results.map((result) => ({
  //                 student: { name: student.name, id: studentId },
  //                 tasks: result.tasks || [],
  //               }))
  //             : []
  //       ),
  //     };
  //   });

  //   return Object.values(initialData); // Convert map back to array for rendering
  // }, [classList, classesResults, toggleScoreType]);

  const formattedClassData = useMemo(() => {
    const initialData = classList
      .filter(({ classId, name }) => classId && name) // ✅ Filter out any invalid class entries
      .reduce(
        (acc, { classId, name }) => ({
          ...acc,
          [classId]: { id: classId, name, taskScores: {}, assessmentResults: [] },
        }),
        {}
      );

    Object.entries(classesResults).forEach(([classId, classObj]) => {
      if (!classId || !classObj || !initialData[classId]) return; // ✅ Skip invalid data

      const students = classObj.students || {};
      const classTaskScores = {};

      Object.entries(students).forEach(([studentId, student]) => {
        student.results?.forEach((result) => {
          result.groupedTasks?.forEach((group) => {
            if (!group.name || !group.aggregated) return; // ✅ Skip invalid task data

            const taskName = group.name;
            const score = toggleScoreType
              ? group.aggregated.groupPercentile
              : group.aggregated.score * 100;

            if (!classTaskScores[taskName]) {
              classTaskScores[taskName] = [];
            }
            classTaskScores[taskName].push(score);
          });
        });
      });

      // ✅ Ensure there are valid scores before calculating averages
      Object.keys(classTaskScores).forEach((taskName) => {
        const scores = classTaskScores[taskName];
        if (scores.length > 0) {
          const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
          classTaskScores[taskName] = averageScore.toFixed(2);
        } else {
          delete classTaskScores[taskName]; // ✅ Remove empty score entries
        }
      });

      // ✅ Ensure we only include valid student data
      const validAssessmentResults = Object.entries(students)
        .map(([studentId, student]) => ({
          student: { name: student.name || "Unknown", id: studentId },
          tasks: student.results?.flatMap((result) => result.groupedTasks || []) || [],
        }))
        .filter(({ student, tasks }) => student.id && tasks.length > 0); // ✅ Remove students with no results

      initialData[classId] = {
        ...initialData[classId],
        taskScores: classTaskScores,
        assessmentResults: validAssessmentResults,
      };
    });

    // ✅ Ensure we filter out any incomplete class objects
    return Object.values(initialData).filter((data) => data.id && data.name);
  }, [classList, classesResults, toggleScoreType]);


  console.log("formattedClassData:", formattedClassData);

  const allStudentsResults = useMemo(() => {
    return Object.values(classesResults)
      .flatMap((classObj) => {
        return Object.entries(classObj.students).map(([id, student]) => {
          const studentData = {
            student: { name: student.name, id: id },
            tasks: student.results
              ? student.results.flatMap((result) => {
                return result.tasks || [];
              })
              : [],
          };

          return studentData;
        });
      })
      .filter((student) => {
        return student.tasks.length > 0; // Filter out students with no tasks
      });
  }, [classesResults]);

  const averageStandardScoresPerTask = useMemo(() => {
    // Create a map to store task-based scores for each task
    const taskScores = {};

    // Flatten student data and collect scores per task
    Object.values(classesResults).flatMap((classObj) => {
      return Object.entries(classObj.students).map(([id, student]) => {
        // Loop over each task for a student and accumulate the standardScores
        student.results?.forEach((result) => {
          result.tasks?.forEach((task) => {
            if (taskScores[task.name]) {
              taskScores[task.name].push(task.standardScore); // Add standardScore for the task
            } else {
              taskScores[task.name] = [task.standardScore]; // Initialize the task with the score
            }
          });
        });
      });
    });

    // Calculate the average standardScore for each task
    const averageStandardScore = Object.keys(taskScores).map((taskName) => {
      const scores = taskScores[taskName];
      const averageStandardScore =
        scores.reduce((sum, score) => sum + score, 0) / scores.length;
      return {
        taskName,
        averageStandardScore,
      };
    });

    return averageStandardScore;
  }, [classesResults]);

  const averageTaskScores = useMemo(() => {
    const classAverages = {}; // Object to hold average scores for each task by class

    Object.entries(classesResults).forEach(([classId, classObj]) => {
      const students = classObj.students || {};
      const taskScores = {}; // To accumulate scores for this class

      Object.values(students).forEach((student) => {
        const studentResults = student.results || [];
        studentResults.forEach((result) => {
          const tasks = result.tasks || [];
          tasks.forEach((task) => {
            const taskName = task.name;
            const score = toggleScoreType
              ? task.groupPercentile
              : task.score * 100;

            // Initialize the task entry if it doesn't exist
            if (!taskScores[taskName]) {
              taskScores[taskName] = { total: 0, count: 0 };
            }

            // Accumulate the total score and count for each task
            taskScores[taskName].total += score;
            taskScores[taskName].count += 1;
          });
        });
      });

      // Calculate the average score for each task for this class
      const averages = {};
      Object.entries(taskScores).forEach(([taskName, { total, count }]) => {
        averages[taskName] = (total / count).toFixed(2); // Store the average score
      });

      classAverages[classId] = {
        className: classObj.name,
        averages: averages,
      };
    });

    return classAverages;
  }, [classesResults, toggleScoreType]);

  useEffect(() => {
    if (formattedClassData.length > 0) {
      const classId = formattedClassData[0].id; // Extract the classId

      getClass(classId).then((response) => {
        setClassDetails(response);
        setGradeLevel(response.grade_level);
      });

      getClassAssessments(classId).then((response) => {
        setClassAssessments(response);
      });
    }
  }, [formattedClassData]);

  // const handleClick = async (classId) => {
  //   // If class results are already fetched, do nothing
  //   if (classesResults[classId]) {
  //     console.log(`Data for class ${classId} already loaded.`);
  //     return;
  //   }

  //   // Set loading state for the clicked class
  //   setLoadingClassId(classId);

  //   try {
  //     // Fetch results for the specific class
  //     console.log(`Fetching data for class ${classId}...`);
  //     const response = await getClassesAssessmentResults(
  //       [classId],
  //       assessment.id
  //     );

  //     // Update state with fetched results
  //     setClassesResults((prevResults) => ({
  //       ...prevResults,
  //       [classId]: response[classId], // Add the fetched class data
  //     }));
  //   } catch (error) {
  //     console.error(`Error fetching data for class ${classId}:`, error);
  //   } finally {
  //     setLoadingClassId(null); // Reset loading state
  //   }
  // };

  // ...existing state declarations...

  const handleClick = async (classId) => {
    // If class results are already fetched, do nothing
    if (classesResults[classId]) {
      console.log(`Data for class ${classId} already loaded for assessment ${assessment.id}.`);
      return;
    }

    console.log(`Fetching data for class ${classId} for assessment ${assessment.id}...`);
    setLoadingClassId(classId);

    try {
      console.log(`API call to getClassesAssessmentResults with classId=${classId}, assessmentId=${assessment.id}`);
      const response = await getClassesAssessmentResults(
        [classId],
        assessment.id
      );

      console.log(`Received response for class ${classId}:`, Object.keys(response));

      // Process the response with groupClassResults before setting state
      const groupedResults = groupClassResults(response);
      console.log(`Grouped results:`, Object.keys(groupedResults));


      setClassesResults((prevResults) => {
        console.log(`Current classesResults keys before update:`, Object.keys(prevResults));
        const newResults = {
          ...prevResults,
          ...groupedResults,
        };
        console.log(`New classesResults keys after update:`, Object.keys(newResults));
        return newResults;
      });
    } catch (error) {
      console.error(`Error fetching data for class ${classId}:`, error);
    } finally {
      setLoadingClassId(null);
    }
  };

  useEffect(() => {
    if (classList.length > 0) {
      const firstClassId = classList[0].classId; // Get the ID of the first class

      if (!classesResults[firstClassId]) {
        console.log(`Fetching results for the first class: ${firstClassId}`);
        handleClick(firstClassId); // Fetch results for the first class
      }
    }
  }, [classList]); // Runs whenever classList is updated

  console.log("classesResults:", classesResults);

  // const classResults = classesResults[classData.id];

  // Transform `classResults` to extract `groupedTasks` in the correct format
  // const transformedAssessmentResults = Object.values(classResults.students || {})
  //   .map(student => ({
  //     student: student, // Keep student details
  //     tasks: (student.results || []).flatMap(result =>
  //       result.groupedTasks.map(group => ({
  //         name: group.name, // Use grouped name
  //         standardScore: group.aggregated?.standardScore ?? 0, // Aggregated Standard Score
  //         zScore: group.aggregated?.zScore ?? 0,
  //         sTenScore: group.aggregated?.sTenScore ?? 0,
  //         duration: group.aggregated?.duration ?? 0,
  //       }))
  //     )
  //   }))
  //   .filter(student => student.tasks.length > 0);

  // console.log("Transformed Assessment Results:", transformedAssessmentResults);
  console.log("classesResultsd:", classesResults);

  // Add this function to handle assessment changes
  const handleAssessmentChange = (newAssessment) => {
    console.log("SencoStudentsResultsOverview - Changing assessment from:", assessment?.name, "to:", newAssessment?.name);
    console.log("SencoStudentsResultsOverview - Old classesResults keys:", Object.keys(classesResults));

    setSelectedAssessment(newAssessment);
    console.log("SencoStudentsResultsOverview - After setSelectedAssessment");

    setClassesResults({});
    console.log("SencoStudentsResultsOverview - After setClassesResults");

    setIsLoading(true);
    console.log("SencoStudentsResultsOverview - After setIsLoading");

    setLoadingClassId(null);
    console.log("SencoStudentsResultsOverview - After setLoadingClassId");

    setAssessmentResultsByStudent([]);
    console.log("SencoStudentsResultsOverview - After setAssessmentResultsByStudent");

    console.log("SencoStudentsResultsOverview - End of handleAssessmentChange");
  };


  return isLoading ? (
    <div className="loading-container">
      <div className="spinner-container">
        <div className="big-ball-spinner"></div>
        <p>Loading data, please wait...</p>
      </div>
    </div>
  ) : (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <h2 className="page-description-header">SENCO Results Overview</h2>
            <p>Click on a class to view individual student results.</p>
          </Col>
          <Row>
            <Col className="col-5">
              <h4 className="page-description-header">{assessment.name}</h4>
            </Col>
            <Col className="col-1"></Col>
            <Col className="col-6">
              <div className="d-flex align-items-center">
                <h4 className="page-description-header">Select Assessment</h4>
                <SencoAssessmentDropdown
                  classAssessments={classAssessments}
                  classIds={classIds}
                  teachers={teachers}
                  assessmentName={assessment.name}
                  assessment={assessment}
                  teacherid={teacherid}
                  schoolid={schoolid}
                  gradeLevel={gradeLevel}
                  allClassesAssessments={allClassesAssessments}
                  currentTab={activeTab}
                  onAssessmentChange={handleAssessmentChange} // Make sure this prop name matches
                />

              </div>
            </Col>
          </Row>
          <Col className="col-2">
            <ReturnToSencoResults />
          </Col>
          <Col className="col-4">
            <RecommendationsButton onResultsPage={true} />
          </Col>
        </Row>

        <Suspense fallback={<div>Loading tabs...</div>}>
          <Tabs
            defaultActiveKey="overview"
            id="senco-results-tabs"
            className="mb-3"
          >
            {/* First Tab with the Accordion */}
            <Tab eventKey="overview" title="Class Results" onSelect={(tab) => setActiveTab(tab)}>
              <Row>
                <Col className="col-5">
                  <Suspense fallback={<div>Loading score table...</div>}>
                    <ScoreInterpretationTable />
                  </Suspense>
                </Col>
              </Row>
              {isLoading ? (
                <div className="spinner-container">
                  <div className="big-ball-spinner"></div>
                </div>
              ) : (
                <Accordion defaultActiveKey="0">
                  {classList.map((classItem, index) => {
                    const { classId, name } = classItem; // Extract classId and name from the class list

                    if (name.toUpperCase().includes("SET")) {
                      return null;
                    }

                    return (
                      <Accordion.Item eventKey={String(index)} key={classId}>
                        <Accordion.Header onClick={() => handleClick(classId)}>
                          {name}
                          {loadingClassId === classId && <Fallback />}
                        </Accordion.Header>
                        <Accordion.Body>
                          {classesResults[classId] ? (
                            <Suspense fallback={<div>Loading table...</div>}>
                              <SencoStudentsResultsTable
                                key={`results-table-${assessment.id}-${classId}`} // Add this key
                                classesResults={{
                                  [classId]: classesResults[classId],
                                }}// Pass only the relevant class results
                                toggleScoreType={props.toggleScoreType}
                                showAtRiskOnly={showAtRiskOnly}
                                schoolid={schoolid}
                                teacherid={teacherid}
                                classId={classId}
                                assessment={assessment}
                                classDetails={classDetails}
                                classAssessments={classAssessments}
                                teachers={teachers}
                                gradeLevel={gradeLevel}
                                assessmentResultsByStudent={
                                  assessmentResultsByStudent
                                }
                                fromSencoResults={fromSencoResults}
                              />
                            </Suspense>
                          ) : (
                            <p>No data loaded yet for this class.</p>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              )}
            </Tab>

            {/* Performance Overview Tab */}
            <Tab
              eventKey="performance"
              title="Class Analysis"
              onSelect={(tab) => setActiveTab(tab)} onEnter={() => {
                // Call handleClick for each class ID when tab is selected
                classList.forEach((classItem) => {
                  if (!classesResults[classItem.classId]) { // Prevent redundant calls
                    handleClick(classItem.classId);
                  }
                });
              }}>
              <Accordion defaultActiveKey="0">
                {formattedClassData?.map((classData) => {
                  // Skip unwanted classes
                  if (classData?.name?.toUpperCase()?.includes("SET")) {
                    return null;
                  }

                  // Ensure we get the correct class results
                  const classResults = classesResults[classData.id] || {};

                  // Transform `classResults` to extract `groupedTasks`
                  const transformedAssessmentResults = Object.values(
                    classResults.students || {}
                  )
                    .map((student) => ({
                      student: student, // Keep student details
                      tasks: (student.results || []).flatMap((result) =>
                        result.groupedTasks.map((group) => ({
                          name: group.name, // Use grouped task name
                          score: Math.min(
                            Math.max(group.aggregated?.score ?? 0, 0),
                            1
                          ),
                          standardScore: group.aggregated?.standardScore ?? 0,
                          zScore: group.aggregated?.zScore ?? 0,
                          sTenScore: group.aggregated?.sTenScore ?? 0,
                          duration: group.aggregated?.duration ?? 0,
                        }))
                      ),
                    }))
                    .filter((student) => student.tasks.length > 0); // Remove students without tasks

                  return (
                    <Accordion key={classData.id}>
                      <Accordion.Header
                        onClick={() =>
                          !classesResults[classData.id] &&
                          handleClick(classData.id)
                        }
                      >
                        {classData.name}
                        {loadingClassId === classData.id && <Fallback />}
                      </Accordion.Header>
                      <Accordion.Body>
                        {classResults ? (
                          <>
                            {/* Render TaskScoreGraph for the current class */}
                            <SencoTaskScoreGraph
                              tasksData={classData.taskScores} // Already formatted separately
                              onLabelClick={handleTaskClick}
                              className={classData.name}
                            // triggerLoadAllClasses={() => {
                            //   classList.forEach((classItem) => {
                            //     if (!classesResults[classItem.classId]) { // Avoid redundant calls
                            //       handleClick(classItem.classId);
                            //     }
                            //   });
                            // }}
                            />
                            {selectedTask && selectedTaskData && (
                              <TaskDetailChart
                                taskName={selectedTask}
                                data={selectedTaskData}
                                className={classData.name}
                              />
                            )}
                            {/* Render PieThresholdGraph for the current class */}
                            <PieThresholdGraph
                              assessmentResults={transformedAssessmentResults} // Now correctly formatted
                              thresholds={thresholds}
                              fromSencoResults={true}
                              className={classData.name}
                            />
                            <hr />
                            {/* Render ClassroomAnalysis if assessmentResults exist */}
                            {transformedAssessmentResults.length > 0 && (
                              <ClassroomAnalysis
                                assessmentResultsByStudent={
                                  transformedAssessmentResults
                                }
                                thresholds={thresholds}
                              />
                            )}
                          </>
                        ) : (
                          <div>
                            Loading data or select this class to load data...
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion>
                  );
                })}
              </Accordion>
            </Tab>

            {/* Recommendations Tab */}
            <Tab eventKey="recommendations" title="Class Insights" onSelect={(tab) => setActiveTab(tab)}>
              <Accordion defaultActiveKey="0">
                {formattedClassData?.map((classData) => {
                  // Skip certain classes
                  if (classData?.name?.toUpperCase()?.includes("SET")) {
                    return null;
                  }

                  // Ensure we get the correct class results
                  const classResults = classesResults[classData.id] || {};

                  // Transform `classResults` to extract `groupedTasks`
                  const transformedAssessmentResults = Object.values(
                    classResults.students || {}
                  )
                    .map((student) => ({
                      student: student, // Keep student details
                      tasks: (student.results || []).flatMap((result) =>
                        result.groupedTasks.map((group) => ({
                          name: group.name, // Use grouped task name
                          standardScore: group.aggregated?.standardScore ?? 0, // Aggregated Standard Score
                          zScore: group.aggregated?.zScore ?? 0,
                          sTenScore: group.aggregated?.sTenScore ?? 0,
                          duration: group.aggregated?.duration ?? 0,
                        }))
                      ),
                    }))
                    .filter((student) => student.tasks.length > 0); // Remove students without tasks

                  return (
                    <Accordion key={classData.id}>
                      <Accordion.Header
                        onClick={() =>
                          !classesResults[classData.id] &&
                          handleClick(classData.id)
                        }
                      >
                        {classData.name}
                        {loadingClassId === classData.id && <Fallback />}
                      </Accordion.Header>
                      <Accordion.Body>
                        {classResults ? ( // Check if data is loaded
                          <>
                            <TaskScoresComparisonChart
                              assessmentResultsByStudent={
                                transformedAssessmentResults
                              }
                              fromSencoResults={true}
                              className={classData.name}
                            />
                            <TaskScoresComparisonChartSchool
                              assessmentResultsByStudent={
                                transformedAssessmentResults
                              }
                              fromSencoResults={true}
                              className={classData.name}
                              averageStandardScoresPerTask={
                                averageStandardScoresPerTask
                              }
                            />
                          </>
                        ) : (
                          <div>
                            Loading data or select this class to load data...
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion>
                  );
                })}
              </Accordion>
            </Tab>

            {/* <Tab eventKey="recommendations" title="Class Insights">
              <Accordion defaultActiveKey="0">
                {formattedClassData.map((classData) => {
                  // Use direct array mapping
                  if (classData.name.toUpperCase().includes("SET")) {
                    return null; // Filtering out certain classes based on name
                  }

                  return (
                    <Accordion key={classData.id}>
                      <Accordion.Header
                        onClick={() =>
                          !classesResults[classData.id] &&
                          handleClick(classData.id)
                        }
                      >
                        {classData.name}
                        {loadingClassId === classData.id && <Fallback />}
                      </Accordion.Header>
                      <Accordion.Body>
                        {classesResults[classData.id] ? ( // Check if data is loaded
                          <>
                            <TaskScoresComparisonChart
                              assessmentResultsByStudent={
                                classData.assessmentResults
                              }
                              fromSencoResults={true}
                              className={classData.name}
                            />
                            <TaskScoresComparisonChartSchool
                              assessmentResultsByStudent={
                                classData.assessmentResults
                              }
                              fromSencoResults={true}
                              className={classData.name}
                              averageStandardScoresPerTask={
                                averageStandardScoresPerTask
                              } // Assuming this is calculated elsewhere and available
                            />
                          </>
                        ) : (
                          <div>
                            Loading data or select this class to load data...
                          </div> // Provide feedback if data is not yet loaded
                        )}
                      </Accordion.Body>
                    </Accordion>
                  );
                })}
              </Accordion>
            </Tab> */}

            {/* Settings Tab */}
            <Tab eventKey="settings" title="Planning SET" onSelect={(tab) => setActiveTab(tab)}>
              <Accordion defaultActiveKey="0">
                {formattedClassData?.map((classData) => {
                  // Skip classes with "SET" in their name
                  if (classData?.name?.toUpperCase()?.includes("SET")) {
                    return null;
                  }

                  // Ensure a ref exists for each class
                  classRefs.current[classData.id] =
                    classRefs.current[classData.id] || React.createRef();

                  // ✅ Extract class results from `classesResults`
                  const classResults = classesResults[classData.id] || {};

                  // ✅ Transform `classResults` to extract `groupedTasks`
                  const transformedAssessmentResults = Object.values(
                    classResults.students || {}
                  )
                    .map((student) => ({
                      student, // Keep student details
                      tasks: (student.results || []).flatMap((result) =>
                        result.groupedTasks.map((group) => ({
                          name: group.name, // Use grouped task name
                          score: group.aggregated?.score ?? 0, // Keep actual task score
                          standardScore: group.aggregated?.standardScore ?? 0, // Standardized score
                          zScore: group.aggregated?.zScore ?? 0, // Z-score
                          sTenScore: group.aggregated?.sTenScore ?? 0, // STen Score
                          duration: group.aggregated?.duration ?? 0, // Duration if applicable
                        }))
                      ),
                    }))
                    .filter((student) => student.tasks.length > 0); // Remove students without tasks

                  return (
                    <Accordion key={classData.id}>
                      <Accordion.Item eventKey={classData.id}>
                        <Accordion.Header
                          onClick={() =>
                            !classesResults[classData.id] &&
                            handleClick(classData.id)
                          }
                        >
                          {classData.name}
                          {loadingClassId === classData.id && <Fallback />}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col className="blue-button-container">
                              <button
                                className="blue-button"
                                onClick={() => {
                                  setShowPrintView(!showPrintView);
                                  setShowStudentPerformancePrintView(
                                    !showPrintView
                                  );
                                }}
                              >
                                {showPrintView
                                  ? "Hide Alphabetical Print View"
                                  : "View Alphabetical Print View"}
                              </button>
                            </Col>
                          </Row>

                          {showStudentPerformancePrintView && (
                            <>
                              <ReactToPrint
                                trigger={() => (
                                  <button className="blue-button">
                                    Print Alphabetical View
                                  </button>
                                )}
                                content={() =>
                                  classRefs.current[classData.id].current
                                }
                              />
                              <div ref={classRefs.current[classData.id]}>
                                <Suspense
                                  fallback={<div>Loading print...</div>}
                                >
                                  <StudentPerformancePrintViewAlphabetical
                                    assessmentResultsByStudent={
                                      transformedAssessmentResults
                                    }
                                    thresholds={thresholds}
                                  />
                                </Suspense>
                              </div>
                            </>
                          )}

                          {!showPrintView && (
                            <Suspense fallback={<div>Loading chart...</div>}>
                              <StudentPerformancePieChart
                                assessmentResultsByStudent={
                                  transformedAssessmentResults
                                } // ✅ Now properly structured
                                thresholds={thresholds}
                                classDetails={classData}
                                classAssessments={classAssessments}
                                gradeLevel={gradeLevel}
                                teachers={teachers}
                                assessment={assessment}
                                toggleScoreType={toggleScoreType}
                                schoolid={schoolid}
                                teacherid={teacherid}
                                fromSencoResults={fromSencoResults}
                              />
                            </Suspense>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </Accordion>

              <hr />

              {/* ✅ Apply similar transformation to All Students Performance */}
              <Accordion>
                <Accordion.Header>All Students Performance</Accordion.Header>
                <Accordion.Body>
                  <Suspense fallback={<div>Loading chart...</div>}>
                    <AllStudentsPerformancePieChart
                      allStudentsResults={allStudentsResults.map((student) => ({
                        student,
                        tasks: (student.results || []).flatMap((result) =>
                          result.groupedTasks.map((group) => ({
                            name: group.name,
                            score: group.aggregated?.score ?? 0,
                            standardScore: group.aggregated?.standardScore ?? 0,
                            zScore: group.aggregated?.zScore ?? 0,
                            sTenScore: group.aggregated?.sTenScore ?? 0,
                            duration: group.aggregated?.duration ?? 0,
                          }))
                        ),
                      }))}
                      thresholds={thresholds}
                      classDetails={formattedClassData}
                      classAssessments={classAssessments}
                      gradeLevel={gradeLevel}
                      teachers={teachers}
                      assessment={assessment}
                      toggleScoreType={toggleScoreType}
                      schoolid={schoolid}
                      teacherid={teacherid}
                      fromSencoResults={fromSencoResults}
                    />
                  </Suspense>
                </Accordion.Body>
              </Accordion>
            </Tab>

            {/* Search Tab */}
            <Tab eventKey="results3" title="Individual Child" onSelect={(tab) => setActiveTab(tab)}>
              <button
                className="blue-button"
                onClick={() => setShowChildPrintOptions(!showChildPrintOptions)}
              >
                {showChildPrintOptions
                  ? "Hide Print Options"
                  : "View Print Options"}
              </button>
              {showChildPrintOptions && (
                <div className="print-options">
                  <ReactToPrint
                    trigger={() => (
                      <button className="blue-button">
                        Print Individual Child
                      </button>
                    )}
                    content={() => individualChildRef.current}
                  />
                  <ReactToPrint
                    trigger={() => (
                      <button className="blue-button">
                        Print Standard Scores Graph
                      </button>
                    )}
                    content={() => standardScoreGraphRef.current}
                  />
                  <ReactToPrint
                    trigger={() => (
                      <button className="blue-button">
                        Print Progress Between Testing Points
                      </button>
                    )}
                    content={() => candyBearRef.current}
                  />
                </div>
              )}
              <AllStudentsIndividualSearch
                allStudentsResults={allStudentsResults}
                selectedChild={selectedChild}
                setSelectedChild={setSelectedChild}
              />
              <hr></hr>
              <div ref={individualChildRef}>
                <div ref={standardScoreGraphRef}>
                  <StandardScoresGraph selectedChild={selectedChild} />
                  <ScoreStatements selectedChild={selectedChild} />
                </div>
                <hr></hr>
                <div ref={candyBearRef}>
                  <CandyBearAnalysisGraph
                    selectedChild={selectedChild}
                    setSelectedChildID={setSelectedChildID}
                    individualStudentResult={individualStudentResult}
                  />
                </div>
              </div>
            </Tab>


            {/* Class Average Tab */}
            <Tab eventKey="results4" title="Class Average" onSelect={(tab) => setActiveTab(tab)}  >
              <button
                className="blue-button"
                onClick={() => setShowChildPrintOptions(!showChildPrintOptions)}
              >
                {showChildPrintOptions ? "Hide Print Options" : "View Print Options"}
              </button>
              {showChildPrintOptions && (
                <div className="print-options">
                  <ReactToPrint
                    trigger={() => <button className="blue-button">Print Class Averages</button>}
                    content={() => individualChildRef.current}
                  />
                  {/* <ReactToPrint
                    trigger={() => <button className="blue-button">Print Standard Scores Graph</button>}
                    content={() => standardScoreGraphRef.current}
                  />
                  <ReactToPrint
                    trigger={() => <button className="blue-button">Print Progress Between Testing Points</button>}
                    content={() => candyBearRef.current}
                  /> */}
                </div>
              )}
              <Tabs defaultActiveKey="classAverage" id="class-average-sub-tabs">

                {/* Class Average Tab with Class-Specific Data */}
                <Tab eventKey="classAverage" title="Class Average" onSelect={(tab) => setActiveTab(tab)}>
                  <div ref={individualChildRef}>
                    <Accordion defaultActiveKey="0">
                      {formattedClassData?.map((classData) => {
                        // Skip unwanted classes
                        if (classData?.name?.toUpperCase()?.includes("SET")) {
                          return null;
                        }

                        // Ensure we get the correct class results
                        const classResults = classesResults[classData.id] || {};

                        // Transform classResults into the required format for the component
                        const transformedAssessmentResults = Object.values(
                          classResults.students || {}
                        )
                          .map((student) => ({
                            student,
                            tasks: (student.results || []).flatMap((result) =>
                              result.groupedTasks.map((group) => ({
                                name: group.name,
                                score: group.aggregated?.score ?? 0,
                                standardScore: group.aggregated?.standardScore ?? 0,
                                zScore: group.aggregated?.zScore ?? 0,
                                sTenScore: group.aggregated?.sTenScore ?? 0,
                                duration: group.aggregated?.duration ?? 0,
                              }))
                            ),
                          }))
                          .filter((student) => student.tasks.length > 0);

                        return (
                          <Accordion.Item key={classData.id} eventKey={classData.id}>
                            <Accordion.Header
                              onClick={() =>
                                !classesResults[classData.id] && handleClick(classData.id)
                              }
                            >
                              {classData.name}
                              {loadingClassId === classData.id && <Fallback />}
                            </Accordion.Header>
                            <Accordion.Body>
                              {classResults ? (
                                <CandyBearAnalysisGraphClassAverage
                                  selectedChild={selectedChild}
                                  setSelectedChildID={setSelectedChildID}
                                  individualStudentResult={individualStudentResult}
                                  assessmentResultsByStudent={transformedAssessmentResults}
                                  classId={classData.id}
                                  className={classData.name}
                                  classAssessments={classAssessments}
                                  gradeLevel={gradeLevel}
                                  schoolid={schoolid}
                                  teacherid={teacherid}
                                />
                              ) : (
                                <div>Loading data or select this class to load data...</div>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </div>
                </Tab>

                {/* Class Average Comparison Tabs */}
                <Tab
                  eventKey="classAverageComparison"
                  title="Class Average Comparison (Current Screener)"
                  onSelect={(tab) => setActiveTab(tab)} // Update state on tab change
                  onEnter={() => {
                    // Call handleClick for each class ID when tab is selected
                    classList.forEach((classItem) => {
                      if (!classesResults[classItem.classId]) { // Prevent redundant calls
                        handleClick(classItem.classId);
                      }
                    });
                  }}
                >
                  <div ref={individualChildRef}>
                    <div ref={candyBearRef}>
                      <CandyBearAnalysisGraphClassComparison
                        assessmentResultsByStudent={assessmentResultsByStudent} // Pass student results
                        formattedClassData={formattedClassData} // Pass all class results
                      />
                    </div>
                  </div>
                </Tab>



                {/* Uncomment this if you want Class Average Comparison for all Screeners */}
                {/* 
    <Tab eventKey="spareClassAverageTab" title="Class Average Comparison (All Screeners)">
      <div ref={individualChildRef}>
        <div ref={candyBearRef}>
          <CandyBearAnalysisGraphClassAll
            selectedChild={selectedChild}
            setSelectedChildID={setSelectedChildID}
            individualStudentResult={individualStudentResult}
          />
        </div>
      </div>
    </Tab>
    */}



              </Tabs>
            </Tab>
          </Tabs>
        </Suspense>
      </Container>
    </div>
  );
}

export default SencoStudentsResultsOverview;
